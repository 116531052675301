import styles from "./SectionHeader.module.css";

const SectionHeader = ({ text }) => {
  return (
    <div className={styles.sectionHeader}>
      <h2 className={styles.title}>{text}</h2>
      <hr></hr>
    </div>
  );
};
export default SectionHeader;
