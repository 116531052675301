import styles from "./Contacts.module.css";

import { IconContext } from "react-icons";
import { RiLinkedinLine } from "react-icons/ri";
import { RiGithubLine } from "react-icons/ri";
import { BsEnvelope } from "react-icons/bs";
import { FaFileDownload } from "react-icons/fa";

import SectionHeader from "../../sectionHeader/SectionHeader";
import Form from "./form/Form";

const Contacts = () => {
  return (
    <section>
      <SectionHeader text={"Contacts"} />
      <div className={styles.contacts}>
        <IconContext.Provider value={{ className: "social" }}>
          <section className={styles.socialContacts}>
            <ul>
              <li>
                <a
                  href="https://www.linkedin.com/in/marco-di-summa-1a5146182/"
                  target="blanck"
                  rel="noreferrer"
                >
                  <RiLinkedinLine />
                </a>
                Linkedin
              </li>

              <li>
                <a
                  href="https://github.com/mdisumma"
                  target="blanck"
                  rel="noreferrer"
                >
                  <RiGithubLine />
                </a>
                GitHub
              </li>
              <li>
                <a href="./cv/MarcoDiSummaCv.pdf" download>
                  <FaFileDownload />
                </a>
                CV
              </li>
              <li>
                <a href="mailto:mdisumma@yahoo.com">
                  <BsEnvelope />
                </a>
                Email
              </li>
            </ul>
          </section>
        </IconContext.Provider>
        <Form />
      </div>
    </section>
  );
};
export default Contacts;
