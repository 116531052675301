import Title from "./title/Title.js";

import "./Header.css";

function Header() {
  return (
    <>
      <header className="header">
        <Title />
      </header>
    </>
  );
}

export default Header;
