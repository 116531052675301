import styles from "./Title.module.css";

const Title = () => {
  return (
    <div className={styles.title}>
      <h1>Marco Di Summa</h1>
      <p>User Interface designer/Front End Developer</p>
    </div>
  );
};
export default Title;
