import styles from "./Intro.module.css";

const Intro = () => {
  return (
    <div className={styles.intro}>
      Dedicated front-end web developer, passionate about creating responsive,
      user-friendly interfaces and delivering seamless digital experiences.
    </div>
  );
};
export default Intro;
