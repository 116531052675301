import styles from "./Skills.module.css";
import SectionHeader from "../../sectionHeader/SectionHeader";

const Skills = () => {
  return (
    <div className={styles.skills}>
      <SectionHeader text={"Skills"} />
      <ul>
        <li>
          <span className={styles.bold}>Frontend Development:</span> HTML, CSS,
          JavaScript, TypeScript.
        </li>
        <li>
          <span className={styles.bold}>Frameworks:</span> React, Next.js.
        </li>
        <li>
          <span className={styles.bold}>Tools & Libraries:</span> Git, npm, API
          Integration, Generative AI.
        </li>
        <li>
          <span className={styles.bold}>UX/UI Design:</span> Figma, Photoshop,
          Illustrator.
        </li>
        <li>
          <span className={styles.bold}>Methodologies:</span> User Research,
          Personas, Prototyping, Wireframing, User Testing.
        </li>
        <li>
          <span className={styles.bold}>Collaboration:</span> Experience in
          Agile environments (Scrum, Kanban)
        </li>
      </ul>
    </div>
  );
};
export default Skills;
