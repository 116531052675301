import styles from "./Project.module.css";

const Project = (props) => {
  return (
    <div className={styles.project}>
      <div className={styles.image}>
        <img src={props.image} alt="Project Image" />
      </div>
      <div className={styles.content}>
        <h2 className={styles.title}>{props.title}</h2>
        <ul className={styles.details}>
          <li>
            <span className={styles.bold}>Date: </span>
            {props.date}
          </li>
          <li>
            <span className={styles.bold}>Course: </span>
            {props.course}
          </li>
          <li>
            <span className={styles.bold}>Duration: </span>
            {props.duration}
          </li>
          <li>
            <span className={styles.bold}>Location: </span>
            {props.location}
          </li>
          <li>
            <span className={styles.bold}>Collaboration: </span>
            {props.collaboration}
          </li>
        </ul>
        <p className={styles.description}>{props.description}</p>
        <div className={styles.stack}>
          <span className={styles.bold}>Stack: </span>
          <span>{props.stack}</span>
        </div>
      </div>
      <div className={styles.link}>
        <a href={props.github} target="_blank">
          GitHub
        </a>
        <a href={props.website} target="_blank">
          Website
        </a>
      </div>
    </div>
  );
};
export default Project;
