import Social from "./components/social/Social";
import Header from "./components/header/Header";
import Main from "./components/main/Main";
import Footer from "./components/footer/Footer";
import Animation from "./components/animation/Animation";
import "./App.css";

function App() {
  return (
    <div>
      <Animation id={"orange"} />
      <Animation id={"yellow"} />
      <Animation id={"blue"} />
      <Social />
      <Header />
      <Main />
      <Footer />
    </div>
  );
}

export default App;
