import styles from "./Projects.module.css";
import SectionHeader from "../../sectionHeader/SectionHeader";
import Project from "./project/Project";

import Data from "../../data/Data.json";

const Projects = () => {
  return (
    <section className={styles.projectsContainer}>
      <SectionHeader text="Projects" />
      <div className={styles.projects}>
        {Data.map((project) => (
          <Project
            key={project.id}
            image={project.image}
            title={project.title}
            date={project.date}
            duration={project.duration}
            course={project.course}
            location={project.location}
            collaboration={project.collaboration}
            description={project.description}
            stack={project.stack}
            github={project.github}
            website={project.website}
          />
        ))}
      </div>
    </section>
  );
};
export default Projects;
