import React, { useState, useEffect } from "react";
import "./animation.css";

const Animation = (props) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const moveRandomly = () => {
      const maxWidth = window.innerWidth; // Element width
      const maxHeight = window.innerHeight; // Element height

      const randomX = Math.floor(Math.random() * maxWidth);
      const randomY = Math.floor(Math.random() * maxHeight);

      setPosition({ x: randomX, y: randomY });
    };

    const intervalId = setInterval(moveRandomly, 5000); // Move every 5 seconds

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="animation">
      <div
        id={props.id}
        className="element"
        style={{ transform: `translate(${position.x}px, ${position.y}px)` }}
      ></div>
    </div>
  );
};

export default Animation;
