import styles from "./Form.module.css";
import { useState } from "react";
import emailjs from "@emailjs/browser";

const Form = () => {
  const [formData, setFormData] = useState({ message: "" });

  (function () {
    emailjs.init({
      publicKey: "o_wKcN1qgZ8-J479r",
    });
  })();

  const handleChange = (e) => {
    setFormData({ message: e.target.value });
    console.log(e.target.value);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.send("service_gu9mi8n", "template_25huuz7", formData).then(
      (response) => {
        console.log("SUCCESS!", response.status, response.text);
      },
      (error) => {
        console.log("FAILED...", error);
      }
    );
    setFormData({ message: "" });
  };

  return (
    <form onSubmit={sendEmail} className={styles.form}>
      <label htmlFor="message">Get in touch</label>
      <textarea
        id="message"
        name="message"
        value={formData.message}
        onChange={handleChange}
        required
        rows="4"
        cols="10"
      ></textarea>
      <button type="submit">Send</button>
    </form>
  );
};
export default Form;
